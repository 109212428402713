.modelsPage {
  padding: 1em;
}

.pageTitleRow {
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }
}

.nameCell {
  > button {
    margin-right: 0.5em;
  }
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-align: left;

  color: #069;
  text-decoration: underline;
  cursor: pointer;
}


.headerCell {
  font-weight: 600;
}
