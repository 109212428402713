.app {
    height: 100vh;
    display: flex;
    flex-flow: column;
    background: var(--theme-primary-background-color);
    min-width: fit-content;
}

.topbar {
    background: #1c1c1c;
    height: 40px;
    position: relative;
    z-index: 5;
    flex: 0 0 auto;
    display: flex;

    h1 {
        font-size: 15px;
        color: var(--theme-primary-text-on-color);
        padding: 0 20px;
    }
}

.main {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    position: relative;
    flex: 1 1 auto;
    min-height: 0;
    height: 100%;
}

.sidebar {
    display: flex;
    flex-flow: column;
    width: 280px;
    background: rgb(233, 233, 233);;
    margin-left: 5px;

    .tabContent {
        display: flex;
        flex-direction: row;
        // padding: 0.25rem 0;
        > svg {
            margin-right: 10px;
        }
    }
}
