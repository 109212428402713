.collectionPage {
  padding: 1em;

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1em 0;
  }

  .divider {
    flex: 0;
  }
}

.pageTitleRow {
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }

  .close {
    margin-left: auto;
    border: none;
  }
}

.field {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  .label {
    font-weight: 600;
    width: 15rem;
  }
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-align: left;

  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
