.collectionsPage {
  padding: 1em;
}

.pageTitleRow {
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }
}

.toolbar {
  border-bottom: 1px solid var(--theme-ui-border-color);
  margin-bottom: 1em;
}

.headerCell {
  font-weight: 600;
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  text-align: left;

  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.nameCell {
  > button {
    margin-right: 0.5em;
  }
}

.latestCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  .spinner {
    margin-right: 1em;
  }
}
