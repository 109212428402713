.layerPage {
  padding: 1em;

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1em 0;
    row-gap: 1em;
  }

  .divider {
    flex: 0;
  }

  .attributes {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}

.pageTitleRow {
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }

  .close {
    margin-left: auto;
    border: none;
  }
}

.field {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  .edit {
    flex: 0;
    border: none;
    align-self: flex-start;

    margin-right: 0.5em;
  }
  .label {
    font-weight: 600;
    width: 10rem;
    align-self: flex-start;
  }
  .value {
    flex: 1;
  }
}

.editTextBody {
  display: flex;
  flex-direction: column;

  >label {
    font-weight: 600;
  }
  .textarea {
    flex: 1;
  }
}
