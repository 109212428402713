.loginPage {
    margin: 45vh auto 0;
  
    display: flex;
    flex-flow: column;
    padding: 1rem;
    border-radius: 3px;
    background-color:  var(--theme-primary-background-color);
    box-shadow: 0 0 3px var(--theme-modal-overlay);
  
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
    }
  
    input {
      margin-left: 1rem;
      border: none;
      border-radius: 3px;
      background: var(--theme-secondary-background-color);
      padding: 0.25rem;
    }
  
    button {
      margin: 0 auto;
      padding: 0.5rem 1rem;
      border: none;
      background: var(--theme-primary-color);
      color: var( --theme-teriary-text-color);
      border-radius: 3px;
  
      &:active {
        transform: translate(1px, 1px);
      }
    }
  }
  
  .error {
    color: var(--theme-red-primary);
    margin-top: 0.5rem;
    text-align: center;
    white-space: pre-line;
  
    &:first-child {
      margin-top: 0;
    }
  }
  