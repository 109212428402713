
.modelPage {
  padding: 1em;

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1em 0;
  }

  .divider {
    flex: 0;
  }
}


.pageTitleRow {
  display: flex;

  align-items: center;

  > svg {
    margin-right: 10px;
  }

  .close {
    margin-left: auto;
    border: none;
  }
}

.headerCell {
  font-weight: 600;
}
