html {
    font-size: 14px;
  }
  
  body {
    margin: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    [data-rbd-drag-handle-context-id] {
      cursor: pointer;
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  button,
  input {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
  
  button {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    padding: 0;
  }