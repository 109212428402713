.dialogSurface {
  max-width: 90vw !important;
}

.dialogContent {
  display: flex;
  flex-direction: column;
}



.embeddingHighlight  {
  position: relative;
  background-color: #f8f887;
  border: 1px  dotted  black;
  border-radius: 8px;
  padding: 0 4px;
  margin: 2px;
}

.removedHighlight  {
  position: relative;
  background-color: #f8878765;
  border: 1px  dotted  black;
  border-radius: 8px;
  padding: 0 4px;
  margin: 2px;
}

.embeddingToken {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 9px;
  color: #000000;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid black;
  padding: 0 0.5em;

  margin: 0;
}