// @import '@fluentui/react/dist/sass/References';

$theme-primary: #6264a7;
$theme-lighter-alt: '#f7f7fb';
$theme-lighter: '#e1e1f1';
$theme-light: '#c8c9e4';
$theme-tertiary: '#989ac9';
$theme-secondary: '#7173b0';
$theme-dark-alt: '#585a95';
$theme-dark: '#4a4c7e';
$theme-darker: #37385d;
$neutral-lighter-alt: '#faf9f8';
$neutral-lighter: '#f3f2f1';
$neutral-light: '#edebe9';
$neutral-quaternary-alt: '#e1dfdd';
$neutral-quaternary: '#d0d0d0';
$neutral-tertiary-alt: '#c8c6c4';
$neutral-tertiary: '#a19f9d';
$neutral-secondary: '#605e5c';
$neutral-primary-alt: '#3b3a39';
$neutral-secondary-alt: '#e1dfdd';
$neutral-primary: '#323130';
$neutral-dark: '#201f1e';
$black: '#000000';
$white: '#fff';

//$font-family: $ms-font-family-fallbacks;
//$font-size-14: $ms-font-size-14;

:root {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Light
  --theme-modal-overlay: #0006;
  --theme-primary-color:#6264a7;
  --theme-primary-color-dark-alt: #585a95;
  --theme-primary-color-dark:#4a4c7e;
  --theme-primary-color-transparent:#6264a740;
  --theme-primary-color-transparent2:#6264a7b7;
  --theme-primary-color-transparent3:#7766bb11;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-tab-hover-color:#a9abce;
  --theme-primary-hover-color: #f1f2f3;
  --theme-dark-color: #464474;
  --theme-light-color:#e9eaf6;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#6264a7;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F7F7FB;
  // Theme - End
}

[data-theme='dark'] {
  background-color: #141414;
  color-scheme: dark;
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  color:white;
  fill:white;

  // Theme - Dark
 --theme-modal-overlay: #67656566;
  --theme-primary-color: #6264a7;
  --theme-primary-color-dark-alt: #585a95;
  --theme-primary-color-dark: #4a4c7e;
  --theme-primary-color-transparent: #6264a740;
  --theme-primary-color-transparent2: #6264a7b7;
  --theme-primary-color-transparent3: #7766bb11;
  --theme-primary-background-color: #141414;
  --theme-primary-background-color-transparent: #14141499;
  --theme-primary-tab-hover-color: #3a3a46;
  --theme-primary-hover-color:  #1f1f1f;
  --theme-dark-color: #464474;
  --theme-light-color: #1f1f1f;
  --theme-primary-text-color: #fff;
  --theme-primary-text-on-color: #000000;
  --theme-secondary-background-color: #1f1f1f;
  --theme-secondary-background-transparent-color: #1f1f1fcc;
  --theme-secondary-text-color: #dbdbdb;
  --theme-secondary-background-hover-color: #1f1f1f;
  --theme-tertiary-background-color: #0a0a0a;
  --theme-teriary-text-color: white;
  --theme-header-text-color: #bebebe;
  --theme-dark-text-color: #000000;
  --theme-dark-icon-color: #c5bfb8;
  --theme-disabled-text-color: #9c9c9c;
  --theme-selected: #ddd;
  --theme-grey-background-color: #f5f5f5;
  --theme-primary-color: #6264a7;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #b4b4b4;
  --theme-ui-border-color-lighter: #616161;
  --theme-ui-border-color-darker: #9f9f9f;
  --theme-ui-border-color-darkest: #b4b4b4;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#1F1F1F;
  // Theme - End
}
[data-theme='red'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Red
  --theme-modal-overlay: #0006;
  --theme-primary-color:#b63225;
  --theme-primary-color-dark-alt: #a42d21;
  --theme-primary-color-dark:#7f231a;
  --theme-primary-color-transparent:#b6322540;
  --theme-primary-color-transparent2:#b63225b7;
  --theme-primary-color-transparent3:#b6322511;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-tab-hover-color:#d78e87;
  --theme-primary-hover-color: #f1f2f3;
  --theme-dark-color: #7f231a;
  --theme-light-color: #f4e0de;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#b63225;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#FBF5F4;
  // Theme - End
}

[data-theme='red2'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Red2
  --theme-modal-overlay: #0006;
  --theme-primary-color:#bd1020;
  --theme-primary-color-dark-alt: #aa0e1d;
  --theme-primary-color-dark:#840b16;
  --theme-primary-color-transparent:#bd102040;
  --theme-primary-color-transparent2:#bd1020b7;
  --theme-primary-color-transparent3:#bd102011;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-tab-hover-color:#f5dbde;
  --theme-primary-hover-color: #f1f2f3;
  --theme-dark-color: #840b16;
  --theme-light-color: #f4e0de;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#bd1020;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#FCF3F4;
  // Theme - End
}

[data-theme='blue'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Blue
  --theme-modal-overlay: #0006;
  --theme-primary-color:#4161e1;
  --theme-primary-color-dark-alt: #5471e4;
  --theme-primary-color-dark:#2e449e;
  --theme-primary-color-transparent:#4161e140;
  --theme-primary-color-transparent2:#4161e140;
  --theme-primary-color-transparent3:#4161e111;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-tab-hover-color:#97a8ef;
  --theme-primary-hover-color: #f1f2f3;
  --theme-dark-color: #2e449e;
  --theme-light-color:#e3e7fb;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#4161e1;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F3F4F6;
  // Theme - End
}

[data-theme='darkblue'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - DarkBlue
  --theme-modal-overlay: #0006;
  --theme-primary-color: #051f44;
  --theme-primary-color-dark-alt: #051c3d;
  --theme-primary-color-dark: #041630;
  --theme-primary-color-transparent:#051f4440;
  --theme-primary-color-transparent2:#051f44b7;
  --theme-primary-color-transparent3:#051f4411;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #768498;
  --theme-dark-color: #041630;
  --theme-light-color: #dadde3;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#051f44;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F3F4F6;
  // Theme - End
}

[data-theme='smokeblue'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - SmokeBlue
  --theme-modal-overlay: #0006;
  --theme-primary-color: #374c69;
  --theme-primary-color-dark-alt: #32445f;
  --theme-primary-color-dark: #27354a;
  --theme-primary-color-transparent:#374c6940;
  --theme-primary-color-transparent2:#374c69b7;
  --theme-primary-color-transparent3:#374c6911;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #919dad;
  --theme-dark-color: #27354a;
  --theme-light-color: #e1e4e9;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#374c69;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F5F6F8;
  // Theme - End
}

[data-theme='gold'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Gold
  --theme-modal-overlay: #0006;
  --theme-primary-color:#d4af37;
  --theme-primary-color-dark-alt: #bf9e32;
  --theme-primary-color-dark:#947a27;
  --theme-primary-color-transparent:#d4af3740;
  --theme-primary-color-transparent2:#d4af37b7;
  --theme-primary-color-transparent3:#d4af3711;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-tab-hover-color:#e7d391;
  --theme-primary-hover-color: #f1f2f3;
  --theme-dark-color: #947a27;
  --theme-light-color:#f9f3e1;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#d4af37;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F4F8F6;
  // Theme - End
}

[data-theme='green'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Green
  --theme-modal-overlay: #0006;
  --theme-primary-color: #217346;
  --theme-primary-color-dark-alt: #1e683f;
  --theme-primary-color-dark: #175131;
  --theme-primary-color-transparent:#21734640;
  --theme-primary-color-transparent2:#217346b7;
  --theme-primary-color-transparent3:#21734611;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #85b299;
  --theme-dark-color: #175131;
  --theme-light-color: #deeae3;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#217346;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F4F8F6;
  // Theme - End
}

[data-theme='orange'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Orange
  --theme-modal-overlay: #0006;
  --theme-primary-color: #fba92c;
  --theme-primary-color-dark-alt: #e29828;
  --theme-primary-color-dark: #b0761f;
  --theme-primary-color-transparent:#fba92c40;
  --theme-primary-color-transparent2:#fba92cb7;
  --theme-primary-color-transparent3:#fba92c11;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #fdd08b;
  --theme-dark-color: #b0761f;
  --theme-light-color: #fef2df;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#fba92c;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#FFFBF4;
  // Theme - End
}

[data-theme='teal'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Teal
  --theme-modal-overlay: #0006;
  --theme-primary-color: #1d7293;
  --theme-primary-color-dark-alt: #1a6784;
  --theme-primary-color-dark: #145067;
  --theme-primary-color-transparent:#1d729340;
  --theme-primary-color-transparent2:#1d7293b7;
  --theme-primary-color-transparent3:#1d729311;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #83b1c4;
  --theme-dark-color: #145067;
  --theme-light-color: #ddeaef;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#1d7293;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F4F8FA;
  // Theme - End
}

[data-theme='violet'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Violet
  --theme-modal-overlay: #0006;
  --theme-primary-color: #7d4a76;
  --theme-primary-color-dark-alt: #71436a;
  --theme-primary-color-dark: #583453;
  --theme-primary-color-transparent:#7d4a7640;
  --theme-primary-color-transparent2:#7d4a76b7;
  --theme-primary-color-transparent3:#7d4a7611;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #b89bb4;
  --theme-dark-color: #583453;
  --theme-light-color: #ece4ea;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#7d4a76;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F9F6F8;
  // Theme - End
}

[data-theme='lime'] {
  --theme-primary: #{$theme-primary};
  --background: #{$white};
  --text-primary: #{$black};
  --text-tertiary: #{$white};
  // --font-family: #{$font-family};
  // --font-size-14: #{$font-size-14};
  
  // Theme - Lime
  --theme-modal-overlay: #0006;
  --theme-primary-color: #88d317;
  --theme-primary-color-dark-alt: #7abe15;
  --theme-primary-color-dark: #5f9410;
  --theme-primary-color-transparent:#88d31740;
  --theme-primary-color-transparent2:#88d317b7;
  --theme-primary-color-transparent3:#88d31711;
  --theme-primary-background-color:#ffffff;
  --theme-primary-background-color-transparent: #fff8;
  --theme-primary-hover-color: #f1f2f3;
  --theme-primary-tab-hover-color: #bee77f;
  --theme-dark-color: #5f9410;
  --theme-light-color: #edf8dc;
  --theme-primary-text-color:#000;
  --theme-primary-text-on-color: #ffffff;
  --theme-secondary-background-color: #f5f5f5;
  --theme-secondary-background-transparent-color: #f5f5f5cc;
  --theme-secondary-text-color:#424242;
  --theme-secondary-background-hover-color:#f1f2f3;
  --theme-tertiary-background-color:#e1e1e1;
  --theme-teriary-text-color:white;
  --theme-header-text-color: #777;
  --theme-dark-text-color:#000000;
  --theme-dark-icon-color: #323130;
  --theme-disabled-text-color:#9c9c9c;
  --theme-selected:#ddd;
  --theme-grey-background-color:#f5f5f5;
  --theme-icon-color:#88d317;
  --theme-has-timeframe: #999;
  --theme-time-selection: #a9a9a9;
  --theme-count: #888;
  --theme-ui-border-color: #d5d3d2;
  --theme-ui-border-color-lighter: #f3f2f1;
  --theme-ui-border-color-darker: #b5b5b5;
  --theme-ui-border-color-darkest : #888;
  --theme-calendar-background:#9988dd44;
  --theme-calendar-border:#7766bb;
  --theme-calendar-background-transparent: #7766bb11;
  --theme-favourite-color: #0074e8;
  --theme-link-color: #0000ee;
  --theme-blue: #0170c1;
  --theme-blue-light: #02b1f1;
  --theme-brown: #ac8771;
  --theme-brown-dark: #9f5c40;
  --theme-brown-light: #c27f03;
  --theme-list-dark-grey: #808080;
  --theme-red-primary: red;
  --theme-red: #c3344c;
  --theme-red-light: #fe7171;
  --theme-light-red: #c00;
  --theme-red-background: #ffbdad;
  --theme-green: #386304;
  --theme-green-secondary: #dbebc7;
  --theme-dark-green: #05aa02;
  --theme-light-green: #b2de84;
  --theme-off-work-dark: #c3deca;
  --theme-off-work-light: #eef5ef;
  --theme-calendar: #e4dff6;
  --theme-call: #f7dfe4;
  --theme-work: #bcd5ed;
  --theme-private-color: #a67ebc;
  --theme-holiday-color: #ed7d31;
  --theme-leave-color: #ffc002;
  --theme-break-color: #fef201;
  --theme-unclassified-color: #bfbfbf;
  --theme-question-new-color: #617d99;
  --theme-question-read-color: #b2b3b1;
  --theme-project-background-1-color:#F0F8E6;
  --theme-no-video:#605e5c;
  --theme-orange: #ff8500;
  --theme-light-orange: #efe4b0;
  --theme-purple: #800080;
  --theme-purple-primary: #b259c4;
  --theme-pink: #ac2d7e;
  --theme-pink-secondary: #fbddf0;
  --theme-yellow-primary: #dad61f;
  --theme-yellow: #6d5700;
  --theme-yellow-secondary: #f5edce;
  --theme-neutral-tertiary-alt: #c8c6c4;
  --theme-lime: #00ff00;
  --theme-cyan: #00ffff;
  --theme-magenta: #ff00ff;
  --theme-text-secondary: #222;
  --theme-very-light-color:#F9FDF3;
  // Theme - End
}
